import {useAuthStore} from "~/stores/auth"

export default defineNuxtRouteMiddleware(async (to) => {
    const app = useNuxtApp()
    const store = useAuthStore(app.$pinia)

    await store.initAuth()

    if (store.user) {
        const query = to.query

        delete query.email
        delete query.password

        return navigateTo({
            path: '/import',
            query,
        })
    }
})